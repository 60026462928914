<template>
    <div>
           <b-alert
            :show="alertShow"
            variant="danger"
            >
            <span  v-html="message"></span>
            </b-alert>
    </div>
</template>
<script>
import qs from "qs";
import ParameterService from "@/services/ParameterService";
import ConstantService from "@/services/ConstantService";
export default {
    props: {
        user: {
            type:String
        }
    },
    data() {
        return {
            message:null,
            code:'semester_statuses',
            status:null,
            alertShow:false,
        }
    },
  async  created() {
        await this.findUserStatus(this.user)
        this.messages(this.$i18n.locale)
    },
    watch:{
        user(newValue) {
            this.findUserStatus(newValue)
        },
        'this.$i18n.locale':{
             handler(value){
                 this.messages(value)
             }
        }
   },
    methods:{
        messages(lang){
            const config = {
                params: {
                    key: lang=='tr' ?"semester_status_warning":"semester_status_warning_en"
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            ConstantService.getValue(config)
                .then(response=>{
                    if(response.data.data!=null){
                        this.alertShow=true
                        let mess=response.data.data.replace('semester_status',this.status)
                        this.message=mess
                    }else {
                        this.alertShow=false
                    }
                }).catch(err=>{
                   this.showErrors(err)
            })
        },
      async  findUserStatus(user){
            const config = {
                params: {
                    parameter_code: this.code,
                    limit: -1,
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            };
         await  ParameterService.getItemsPublic(config)
                .then((response) => {
                    const data =response.data.data
                    data.forEach(item=>{
                        if (item.code==user){
                            this.status=this.$i18n.locale=='tr' ?item.name:item.name_en
                        }
                    })
                })
        }
    }
};
</script>
